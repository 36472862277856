<template>
    <div class="page">
        <div id="top" class="search_wap">
            <div class="a-inline-block">
                  <label for="">月份</label>
               <a-select v-model="onMonth" style='width:100px'>
                    <a-select-option  :value="item" v-for="(item,index) in monthList" :key="index">{{item}}</a-select-option>
                </a-select>
               
            </div>
        <div class="a-inline-block">
             <label for="">工人姓名</label>
                <a-input v-model="onTemp" class="sInput minInput" placeholder="工人姓名"/>

                  <!-- <a-select v-model="onTemp" style='width:100px'>
                       <a-select-option value="">全部</a-select-option>
                        <a-select-option :value="item.id" v-for="(item,index) in tempList" :key="index">{{item.name}}</a-select-option>
                </a-select> -->
            </div>
        <a-icon type="search" @click="onSearch"/>
        </div>
        
        
        <div v-if="this.pagination.total" style="text-align: right;margin-right: 20px;">当前数据条数{{this.pagination.total}}条</div>
        <div v-infinite-scroll="getData"
        infinite-scroll-disabled="dataLoadStop"
        infinite-scroll-distance="50">
        <div class="lists" v-for="(item,index) in data" :key="index">
            <div class="list_row">
                <div class="flex">
                    <div><label for="">姓名：</label>{{item.tname}}</div>
                    <div><label for="">月工时：</label>{{item.stime}}</div>
                    <div><label for="">月薪资：</label>{{item.smoney}}</div>
                </div>
                 <div class="flex">
                  <div class="showSpan" v-for="(itm,idx) in item.attendance" :key="idx">
                        {{itm.date}}<span class="lineSpan">|</span>{{itm.sname}}<span class="lineSpan">|</span>{{itm.time}}<span class="lineSpan">|</span>{{itm.money}}
                  </div>
                </div>
            </div>
        </div>
        <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div> -->
            
            <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChange" v-model="pagination.current" :total="pagination.total" />
            <div v-if="pagination.total==0" class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>--暂无数据--</span>
            </div>
          </div>
         </div>
         <!-- <a-pagination
                v-if="data.length>0"
                class="page_select"
                v-model="pagination.current"
                :total="pagination.total"
                @change="pagesChange"
            /> -->
</template>
<script>
// import $ from "jquery";
import {requestXml} from '../../../assets/js/request';
import infiniteScroll from "vue-infinite-scroll";

export default {
    directives: {infiniteScroll},  

    data() {
        return {
            // 搜索的内容
            key: "",
            data: [],
            monthList:'', //可选月份列表
            onMonth:'',
            tempList:'',  //工人列表
            onTemp:'',
                // 分页
            dataLoadStop: true,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
            },
        };
    },
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('curpage',JSON.stringify({'date':this.onMonth,'name':this.onTemp,'page':this.pagination}))
    },
    mounted: function(){
        if(this.fromPath == "/scrm_wap/task/taskDetail" || this.fromPath == '/scrm_wap/task/taskAdd') {
            var data=JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination=data.page
            this.onMonth=data.date
            this.onTemp=data.name
        }
        this.getMonth();
        this.getTemp();
    },
    methods:{
        
        onChange(){
            window.location.href='#top'
            this.getData();
        },
         // 搜索
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getData(this.key);
        },
               // 获取工人列表
        getTemp(){
             requestXml("/jieb/Temporary/tempbaseshow","POST",(res) => {
                this.tempList = res.list;
            },{"page":{"curpage":1,"pagesize":9999}})
        },
        // 获取考勤月份
        getMonth(){
            requestXml("/jieb/Attendance/month","POST",(res) => {
                console.log(res);
                this.monthList=res;
                this.onMonth=res[0];
                this.onSearch();
            })
        },
         getData() {
             this.dataLoadStop = true;
            requestXml("/jieb/Attendance/attendanceshow","POST",(res) => {
                this.data = res.list;
                //  this.data = this.data.concat(res.list);
                // if(res.page.maxpage>this.pagination.current){
                // this.pagination.current += 1
                // this.dataLoadStop = false
                // }else{
                // this.dataLoadNomore = true
                // }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{date:this.onMonth,'name':this.onTemp,
                page: {
                    curpage: this.pagination.current,
                    pagesize: this.pagination.pageSize,
                }
            })
        },
    }
};
</script>
<style scoped>
.minInput{
        width: 120px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .page_select {
        position: fixed;
        bottom: 0px;
        display: flex;
        justify-content: center;
        width: 100%;
        background: #fff;
    }
    .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .search_wap .ant-input-search{
        width: 90vw;
        margin: 0 5vw;
    }.a-inline-block{
        display: flex;
        align-items: center;
        height: 40px;
    }

    .lists{
        width: 92%;
        /* height: 26rem; */
        padding: 3rem;
        margin: 15px auto;
        background-color: #fff;
        border-radius: 10px;
        color: #202020;
    }
    /* .lists .list_row div{
        height: 5rem;
        line-height: 5rem;
        text-align: left;
    } */
    .lists .list_row .flex{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 5px 0;
    }
    

    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  

    
</style>
